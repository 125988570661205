import React from "react";
import styles from "./siamese-one-shot.module.css";
console.log(styles);

const SiameseV2 = props => (
  <svg
    width="800"
    height="600"
    viewBox="0 0 1052.362 744.094"
    id="svg4410"
    {...props}
  >
    <defs id="defs4412">
      <marker orient="auto" id="arrowdefault" overflow="visible">
        <path
          id="arrowdefaultpath"
          d="M-1.926-1.21L1.352-.005l-3.278 1.206a2.05 2.05 0 0 0 0-2.411z"
          fillRule="evenodd"
          strokeWidth={0.1875}
          strokeLinejoin="round"
          stroke="#1e1935"
          fill="#1e1935"
          fillOpacity={0.4}
        />
      </marker>
      <marker orient="auto" id="arrowfocus" overflow="visible">
        <path
          id="arrowfocuspath"
          d="M-1.926-1.21L1.352-.005l-3.278 1.206a2.05 2.05 0 0 0 0-2.411z"
          fillRule="evenodd"
          strokeWidth={0.1875}
          strokeLinejoin="round"
          stroke="#1e1935"
          fill="#1e1935"
        />
      </marker>
    </defs>
    <g id="layer1" transform="translate(0 -308.268)">
      <g id="layer12" className={styles.merged_model}>
      <path
          d="M262.885 718.749l227.154 89.905"
          id="network_left_out"
          className={styles.merged_model_input}
        />      
        <path
          id="network_right_out"
          d="M779.96 716.741l-230.02 91.353"
          className={styles.merged_model_input}          
        />
        
        <g id="layer13">
          <g id="layer14">
            <text
              style={{ lineHeight: "125%" }}
              x={501.941}
              y={941.682}
              id="text8537"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan8539"
                x={501.941}
                y={941.682}
                fontSize={25}
                fill="#b3b3b3"
              >
                fc 1
              </tspan>
            </text>
          </g>
          <rect
            y={910.219}
            x={369.833}
            height={44.286}
            width={308.905}
            id="fc1"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
        </g>
        <g id="g10725">
          <g id="g10727">
            <text
              id="text8541"
              y={862.763}
              x={483.41}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={862.763}
                x={483.41}
                id="tspan8543"
                fontSize={25}
                fill="#b3b3b3"
              >
                merge
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="merge"
            width={308.905}
            height={44.286}
            x={369.833}
            y={836.219}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
            className={styles.merge_layer}
          />
          <path
            id="merge_layer_out"
            className={styles.merge_layer_out}
            d="M522.297 883.072v21.045"
          />
        </g>
        <rect
          y={812.362}
          x={302.857}
          height={160}
          width={442.857}
          id="merged_model"
          className={styles.merged_model}          
          ry={26.374}
          fill="#ccc"
          fillOpacity={0.196}
          stroke="#1d1212"
          strokeWidth={2}
          strokeLinejoin="round"
          strokeDasharray="8,2"
          strokeOpacity={0.455}
        />
      </g>
       <g id="layer6" className={styles.network_right}>
        <rect          
          ry={21.667}
          y={336.913}
          x={566.571}
          height={380}
          width={442.857}
          id="network_right"
          fill="#ccc"
          fillOpacity={0.197}
          stroke="#1d1212"
          strokeWidth={2}
          strokeLinejoin="round"
          strokeDasharray="5.99999996,1.99999999"
          strokeOpacity={0.453}
        />
        <g id="g10563">
          <g id="layer11">
            <text
              id="text7785"
              y={672.053}
              x={741.412}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={672.053}
                x={741.412}
                id="tspan7787"
                fontSize={25}
                fill="#b3b3b3"
              >
                fc 4096
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="fc_4096_2"
            width={308.905}
            height={44.286}
            x={633.547}
            y={640.59}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            id="output_2"
            d="M778.008 686.786v25.783"
            className={styles.maxpool2}
          />
        </g>
        <g id="g10584">
          <g id="g10586">
            <text
              style={{ lineHeight: "125%" }}
              x={699.218}
              y={601.092}
              id="text7781"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan7783"
                x={699.218}
                y={601.092}
                fontSize={25}
                fill="#b3b3b3"
              >
                4x4 conv, 256
              </tspan>
            </text>
          </g>
          <rect
            y={571.124}
            x={633.547}
            height={44.286}
            width={308.905}
            id="conv_4_256_2"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            d="M778.012 616.786v21.046"
            id="maxpool_4_2"
            className={styles.maxpool2}
          />
        </g>
        <g id="g10588">
          <g id="g10590">
            <text
              id="text7777"
              y={531.626}
              x={699.285}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={531.626}
                x={699.285}
                id="tspan7779"
                fontSize={25}
                fill="#b3b3b3"
              >
                4x4 conv, 128
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="conv_4_128_2"
            width={308.905}
            height={44.286}
            x={633.547}
            y={501.658}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            id="maxpool_3_2"
            d="M778.012 544.786v21.046"
            className={styles.maxpool2}
          />
        </g>
        <g id="g10567">
          <g id="g10569">
            <text
              style={{ lineHeight: "125%" }}
              x={698.87}
              y={460.16}
              id="text7773"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan7775"
                x={698.87}
                y={460.16}
                fontSize={25}
                fill="#b3b3b3"
              >
                7x7 conv, 128
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="conv_7_128_2"
            width={308.905}
            height={44.286}
            x={633.547}
            y={430.192}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            className={styles.maxpool2}
            d="M778.012 476.786v21.046"
            id="maxpool_2_2"
          />
        </g>
        <g id="g10571">
          <g id="g10573">
            <text
              id="text7769"
              y={390.694}
              x={694.39}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={390.694}
                x={694.39}
                id="tspan7771"
                fontSize={25}
                fill="#b3b3b3"
              >
                10x10 conv, 64
              </tspan>
            </text>
          </g>
          <rect
            y={360.726}
            x={633.547}
            height={44.286}
            width={308.905}
            id="conv_10_64_2"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            id="maxpool_1_2"
            d="M778.012 404.786v21.046"
            className={styles.maxpool2}
          />
        </g>
      </g>
      <g id="layer5" className={styles.network_left}>
        <g id="layer7">
          <g id="layer8">
            <text
              style={{ lineHeight: "125%" }}
              x={223.412}
              y={672.053}
              id="text7568"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan7570"
                x={223.412}
                y={672.053}
                fontSize={25}
                fill="#b3b3b3"
              >
                fc 4096
              </tspan>
            </text>
          </g>
          <rect
            y={640.59}
            x={115.547}
            height={44.286}
            width={308.905}
            id="fc_4096"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path className={styles.maxpool} d="M260.008 686.786v25.783" id="output_1" />
        </g>
        <g id="g10312">
          <g id="g10314">
            <text
              id="text7564"
              y={601.092}
              x={181.218}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={601.092}
                x={181.218}
                id="tspan7566"
                fontSize={25}
                fill="#b3b3b3"
              >
                4x4 conv, 256
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="conv_4_256"
            width={308.905}
            height={44.286}
            x={115.547}
            y={571.124}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            id="maxpool_4"
            d="M260.012 616.786v21.046"
            className={styles.maxpool}
          />
        </g>
        <g id="g10316">
          <g id="g10318">
            <text
              style={{ lineHeight: "125%" }}
              x={181.285}
              y={531.626}
              id="text7560"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan7562"
                x={181.285}
                y={531.626}
                fontSize={25}
                fill="#b3b3b3"
              >
                4x4 conv, 128
              </tspan>
            </text>
          </g>
          <rect
            y={501.658}
            x={115.547}
            height={44.286}
            width={308.905}
            id="conv_4_128"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            className={styles.maxpool}
            d="M260.012 544.786v21.046"
            id="maxpool_3"
          />
        </g>
        <g id="g10320">
          <g id="g10322">
            <text
              id="text7556"
              y={460.16}
              x={180.87}
              style={{ lineHeight: "125%" }}
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                y={460.16}
                x={180.87}
                id="tspan7558"
                fontSize={25}
                fill="#b3b3b3"
              >
                7x7 conv, 128
              </tspan>
            </text>
          </g>
          <rect
            y={430.192}
            x={115.547}
            height={44.286}
            width={308.905}
            id="conv_7_128"
            ry={7.3}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            id="maxpool_2"
            d="M260.012 476.786v21.046"
            className={styles.maxpool}
          />
        </g>
        <g id="g10324" className="conv1">
          <g id="g10326">
            <text
              style={{ lineHeight: "125%" }}
              x={176.39}
              y={390.694}
              id="text7552"
              fontWeight={400}
              fontSize={40}
              fontFamily="sans-serif"
              letterSpacing={0}
              wordSpacing={0}
            >
              <tspan
                id="tspan7554"
                x={176.39}
                y={390.694}
                fontSize={25}
                fill="#b3b3b3"
              >
                10x10 conv, 64
              </tspan>
            </text>
          </g>
          <rect
            ry={7.3}
            id="conv_10_64"
            width={308.905}
            height={44.286}
            x={115.547}
            y={360.726}
            fill="none"
            fillOpacity={0.576}
            stroke="#000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeOpacity={0.314}
          />
          <path
            className={styles.maxpool}
            d="M260.012 404.786v21.046"
            id="maxpool_1"
          />
        </g>
        <rect
          id="network_left"
          width={442.857}
          height={380}
          x={48.571}
          y={336.913}
          ry={21.667}
          className="quora_example"
          fill="#ccc"
          fillOpacity={0.197}
          stroke="#1d1212"
          strokeWidth={2}
          strokeLinejoin="round"
          strokeDasharray="5.99999996,1.99999999"
          strokeOpacity={0.453}
        />
      </g>
    </g>
  </svg>
);

export default SiameseV2;