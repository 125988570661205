import React from "react"
import BlogPostChrome from "../../components/BlogPostChrome"
import { graphql } from "gatsby"
import { findDOMNode } from "react-dom"
// import siamese_v2 from './images/siamese_v2.svg'
import SiameseV2 from './SiameseV2'
import Poster from './poster.png'
import Layout from './../../components/Layouts';
var d3 = require(`d3`)

export const frontmatter = {
    title: `Siamese Neural Networks for One-shot Image Recognition`,
    written: `2018-03-20`,
    updated: `2018-03-30`,
    layoutType: `post`,
    contentType: "blog",        
    path: `/siamese-one-shot/`,
    category: `Deep learning`,
    image: './poster.png',
    description: `The paper presents a technique to perform one shot learning with one or few examples per dataset.`,
}
  

class SiameseBase extends React.Component {
    constructor(props) {
      super(props)
      // console.log('SiameseBase:'. Poster);
    }
  
    render() {
      let data = this.props.data.markdownRemark
      let html = data.html
  
      return (
        <Layout data={this.props.data} location={this.props.location}>
        <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
         <h1>Siamese Neural Networks for One-shot Image Recognition</h1>
         <span>
            This <a href="https://www.cs.cmu.edu/~rsalakhu/papers/oneshot1.pdf">paper</a> from Koch et. al presents 
            a technique to perform one shot learning with one or few examples per dataset.At a very high level, the idea 
            appears similar to K-Nearest Neighbours except they use features extracted by 
            Convolutional layers instead of direct pixel values. But that is probably an over simplification, 
            the paper shows how to construct a model that jointly trains two identical convolutional neural 
            network to differentiate between images from different categories.
         </span>
         <span>Once the network is trained, it can be used on entirely new classes from unknown distribution.</span>
          <div className="section">
            <div className="">
                <div>
                    <SiameseV2 />
                    <div className="has-text-centered ">
                        <span className="is-size-7 has-text-grey">Proposed network architecture</span>
                    </div>
                </div>
            </div>
          </div>
          <div className="section">
            <div className="">
              <span>The code walkthrough covers my approach to dataset preparation, network implementation and test results.</span>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </BlogPostChrome>
        </Layout>
      )
    }
  }
  
  export default SiameseBase
  

  // We want to keep this component mostly about the code
  //  so we write our explanation with markdown and manually pull it in here.
  //  Within the config, we loop all of the markdown and createPages. However,
  //  it will ignore any files appended with an _underscore. We can still manually
  //  query for it here, and get the transformed html though because remark transforms
  //  any markdown based node.
  export const pageQuery = graphql`
    query siamesequery($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2018-03-30-siamese-one-shot/_siamese_nb/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
    }
  `
  